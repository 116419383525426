<template>
  <div>
    <TitleBold center>
      {{ $t("user.signUp.title") }}
    </TitleBold>
    <MiddleContainer>
      <validation-observer ref="observer">
        <form @submit.prevent.stop="sendRequest">
          <div class="row">
            <div class="col col-12 col-md-4 mb-4">
              <base-input
                v-model="lastName"
                :label="$t('user.fields.lastName')"
                class="mb-2"
                required
                :validate-disabled="false"
              ></base-input>
            </div>
            <div class="col col-12 col-md-4 mb-4">
              <base-input
                v-model="firstName"
                :label="$t('user.fields.firstName')"
                class="mb-2"
                required
                :validate-disabled="false"
              ></base-input>
            </div>
            <div class="col col-12 col-md-4 mb-4">
              <base-input
                v-model="middleName"
                :label="$t('user.fields.middleName')"
                class="mb-2"
                :validate-disabled="false"
              ></base-input>
            </div>
            <div class="col col-12 col-md-4 mb-4">
              <radio-buttons-input
                v-model="sex"
                :label="$t('user.fields.sex')"
                class="mb-2"
                :items="sexItems"
                required
                one-value
                inline
                button
                button-group
                :validate-disabled="false"
              ></radio-buttons-input>
            </div>
            <div class="col col-12 col-md-4 mb-4">
              <base-input
                v-model="phone"
                :label="$t('user.fields.phone')"
                type="tel"
                class="mb-2"
                required
                :validate-disabled="false"
              ></base-input>
            </div>
            <div class="col col-12 col-md-4 mb-4">
              <base-input
                v-model="birthday"
                :label="$t('user.fields.birthday')"
                type="date"
                class="mb-2"
                :validate-disabled="false"
              ></base-input>
            </div>
            <div class="col col-12 col-md-4 mb-4">
              <base-input
                v-model="email"
                :label="$t('user.fields.email')"
                type="email"
                class="mb-2"
                required
                :validate-disabled="false"
              ></base-input>
            </div>
            <div class="col col-12 col-md-4">
              <base-input
                v-model="password"
                :label="$t('user.fields.password')"
                type="password"
                class="mb-2"
                required
                :validate-disabled="false"
              ></base-input>
            </div>
            <div class="col col-12 col-md-4">
              <base-input
                v-model="password2"
                :label="$t('user.fields.password2')"
                type="password"
                :equal="{ field: 'Пароль', equal: password }"
                class="mb-2"
                required
                :validate-disabled="false"
              ></base-input>
            </div>
            <div class="col col-12">
              <div class="text-center">
                <filled-button class="btn-wide" color="primary" type="submit">
                  {{ $t("user.fields.send") }}
                </filled-button>
              </div>
            </div>
          </div>
        </form>
      </validation-observer>
    </MiddleContainer>
  </div>
</template>

<script>
import TitleBold from "../components/base/TitleBold";
import BaseInput from "../components/inputs/BaseInput";
import FilledButton from "../components/buttons/FilledButton";
import MiddleContainer from "../components/base/MiddleContainer";
import API from "../common/api/api.functions";
import { ValidationObserver } from "vee-validate";
import RadioButtonsInput from "../components/inputs/RadioButtonsInput";

export default {
  name: "SignUp",
  components: {
    RadioButtonsInput,
    MiddleContainer,
    FilledButton,
    BaseInput,
    TitleBold,
    ValidationObserver
  },
  data: () => ({
    firstName: null,
    lastName: null,
    middleName: null,
    phone: null,
    sex: null,
    birthday: null,
    sexItems: [
      {
        label: "М",
        value: "male"
      },
      {
        label: "Ж",
        value: "female"
      }
    ],
    email: null,
    password: null,
    password2: null
  }),
  mounted() {
    this.sexItems = [
      {
        label: this.$t("user.fields.sexItems.male"),
        value: "male"
      },
      {
        label: this.$t("user.fields.sexItems.female"),
        value: "female"
      }
    ];
  },
  methods: {
    async sendRequest() {
      for (const nowRef of Object.values(this.$refs.observer.refs)) {
        if (nowRef.disabled) continue;
        nowRef.setFlags({ dirty: true });
      }
      let validation = await this.$refs.observer.validate();
      if (!validation) {
        this.$toast.error("Исправьте ошибки!");
        let refs = this.$refs.observer.refs;
        for (const ref of Object.values(refs)) {
          if (ref.errors.length) {
            ref.$el.scrollIntoView({ block: "center", behavior: "smooth" });
            break;
          }
        }
        return;
      }
      const birthday = new Date(this.birthday).toISOString().split("T")[0];

      API.signUp({
        email: this.email,
        password: this.password,
        first_name: this.firstName,
        last_name: this.lastName,
        middle_name: this.middleName,
        sex: this.sex,
        phone: this.phone,
        birthday: birthday
      })
        .then(() => this.$toast.success("Успешная регистрация"))
        .catch(error => {
          for (const val of Object.entries(error.response.data)) {
            let formattedVal = val[0];
            if (formattedVal === "non_field_errors") formattedVal = "";
            this.$toast.error([formattedVal, val[1].join(", ")].join(": "));
          }
        });
    }
  }
};
</script>
